



import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CategoryCard from '../../components/Categories/CategoryCard';

export default function CategoryScreen() {
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('http://blog-api/category', {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then((json) => {
        setCategoryList(json);
      });
  }, []);



  return (
    <div className='text-white m-auto bg-cyan-900 pt-28'>


      <h1 className='text-center m-auto text-5xl'>Derniers projets</h1>

      <section className='mt-28 m-auto text-center'>

          <img src="../../assets/img/logoSylvain.webp" alt="Logo-Classic-Sport-Drive" className='m-auto w-1/6 mb-8'/>
      <h1 className='text-5xl pb-12'>Classic Sport Driving</h1>
          <button className=' rounded bg-cyan-800 text-white hover:card p-4 text-xl'>
                <a target='blank' href="https://store.steampowered.com/app/1267580/Classic_Sport_Driving/ ">Page Steam</a>
          </button>
          <br />

          <h2 className='text-2xl mt-8'>Projet en cours</h2> <br />
          <p className='mb-8'>Refonte des menus du jeu pour une navigation plus intuitive et agréable en respectant les habitudes utilisateurs. </p><br />


          <div className='flex flex-wrap m-auto w-5/6 text-center justify-center items-start '>

            <div className='flex-col m-auto md:w-1/2  space-y-4'>
              <h2 className='text-3xl'>Avant</h2> <br />
            <img src="../../assets/img/menu1ori.png" alt="Menu-classic-sport-drive.jpg" /> 
            <img src="../../assets/img/menu2ori.png" alt="Menu-classic-sport-drive.jpg" /> 
            </div>


            <div className='flex-col md:w-1/2  md:ml-8 space-y-4'>
              <h2 className='text-3xl'>Après</h2> <br />
            <img src="../../assets/img/menu1mod.png" alt="Menu-classic-sport-drive.jpg" />
            <img src="../../assets/img/menu2mod.png" alt="Menu-classic-sport-drive" />
            </div>


          </div>


      </section>

<div className='bg-white py-28 my-8 '><br /></div>
      <section className='  mt-8  m-auto text-center'>

<img src="../../assets/img/logoBlanc.png" alt="Logo-leading-association-danse" className='m-auto w-1/2 md:w-1/6 mb-8'/>
<h1 className='text-5xl pb-12'>Association "Leading", danses de salon.</h1>
<button className=' rounded bg-cyan-800 text-white hover:card p-4 text-xl'>
      <a target='blank' href="#">Vers le site</a>
</button>
<br />


<p className='mb-8'>Refonte complète du site </p><br />


<div className='flex flex-wrap m-auto w-5/6 text-center justify-center items-start'>

  <div className='flex-col md:w-1/2   space-y-4'>
    <h2 className='text-3xl'>Avant</h2> <br />
  <img src="../../assets/img/ancienSiteKeren.png" alt="Ancien site de l'association leading danses de salon" /> 
  <img src="../../assets/img/ancienSiteKeren2.png" alt="Ancien site de l'association leading danses de salon" /> 
  </div>


  <div className='flex-col md:w-1/2 md:ml-8 space-y-4'>
    <br /><br />
    <h2 className='text-3xl'>Après</h2> <br />
  <img src="../../assets/img/nouvSiteKeren.png" alt="Exemple du nouveau site" />
  <img src="../../assets/img/nouvSiteKren2.png" alt="Exemple du nouveau site" />
  <img src="../../assets/img/nouvSiteKeren3.png" alt="Exemple du nouveau site" />
  </div>


</div>
<div className='bg-white py-28 my-8 '><br /></div>

</section>































                {/* <h1 className='text-5xl mt-28 text-white text-center'>Galerie photos</h1>
    <section className='m-auto my-28  '>
                <div className='flex flex-wrap justify-center space-x-8 space-y-8'>
                    <div></div>
                 
                    <div className='card'> 
                        <img src="../assets/img/parfait.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/jauneviolet.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/titbout1.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/divers/ciel.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/jour de pluie.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/titbout4.jpg" alt="" />
                    </div>
                    <div className='card'> 
                        <img src="../assets/img/divers/immeubles.jpg" alt="" />
                    </div> */}
                 

               

      {/* <div className='md:flex lg:flex-row flex-wrap '>
        {categoryList?.data?.map((category) => (
          <div key={category.Id_category} className='m-auto  basis-1/4 '>
             
            <div
           
            onClick={() =>{
              navigate(`/galerie/${category.Id_category}`); 
            
              }}>

              <CategoryCard title={category.title} img={category.img}  className='' />
            </div>
              
          </div>
        ))}
      </div> */}
    {/* </section> */}
    </div>
  );
}


// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import CategoryCard from '../../components/Categories/CategoryCard';

// export default function CategoryScreen() {
//   const [categoryList, setCategoryList] = useState([]);

//   useEffect(() => {
//     fetch('http://blog-api/category', {
//       method: 'GET',
//     })
//       .then((resp) => resp.json())
//       .then((json) => {
//         setCategoryList(json);
//       });
//   }, []);

//   return (
//     <section className='m-auto'>
//       <div className='flex-col justify-center items-center md:flex md:justify-center md:items-center m-auto'>
//         {categoryList?.data?.map((category) => (
//           <div key={category.Id_category} className='m-auto justify-center w-full'>
//             <Link to={`/galerie?=${category?.Id_category}`}>
//               <CategoryCard title={category.title} img={category.img} />
//             </Link>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }



  // const [articleList,setArticleList]= useState(null);

  

 


  


  // return (
  //   <>
  //   <div className='my-5'>
     
  //   </div>
  //   <div className='lg:flex lg:flex-wrap '>
  //      {articleList?.data.map((item) => {
               
  //              return (
  //                <div className='cardPre rounded basis-2/4 ' key={item.Id_article}>
  //                  <CartePhoto className="rounded"
  //                    title={item.title}
  //                    src={item?.with[0].src}
  //                    content={item.content}
  //                    alt={item?.with[0].alt}
  //                  />
  //                </div>
  //              );
             
  //          })} 
  //   </div>
    // </>
  // )

