/* import React from 'react'

export default function Prestations() {
  return (
    
    <div className='pt-28 text-white bg-cyan-900  m-auto text-center  p-4'>
<h2 className='text-5xl text-center m-auto' id='infographie'>Offres promotionnelles</h2> <br />
<section className='text-white flex justify-center  flex-wrap space-x-8 space-y-8 mb-48 w-full  '>
    <div></div>
    <div className=' w-full m-auto'>
        <div className='flex-col rounded  p-4'>
        <h1 className='text-3xl text-cyan-300 mb-4 p-4'>Pack lancement entreprise</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <div className="flex flex-wrap md:items-start md:justify-center justify-center ">
     

        <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2  my-8 rounded   justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-cyan-300 my-4">Cartes de visite</h3>
          <img src="assets/img/carteV.jpg" alt="" className="object-contain  p-0 m-auto"/>
        </div>
        <p className="text-7xl items-center mx-4 text-cyan-300"></p>

        <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2  my-8 rounded   justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-cyan-300 my-4">Logo</h3>
          <img src="assets/img/logoC.png" alt="" className="object-fill p-0 m-auto"/>
        </div>

          <p className="text-7xl items-center mx-4 text-cyan-300"></p>

<div className="flex-col md:w-1/6 card shadow w-5/6 shadow-black pt-2  my-8 rounded   justify-center items-center space-y-8 text-center p-0 ">
  <h3 className="font-medium  text-3xl text-cyan-300 my-4">Flyer</h3>
  <img src="assets/img/Verso.jpg" alt="" className="object-fill  p-0 m-auto"/>
</div>
<hr className='w-1/2 p-0 m-auto' />
</div>

        </div>
        <div className=' rounded md:p-8  bg-white bg-opacity-5 md:w-1/2 m-auto '>
        <p>Une solution complète pour bien débuter votre activité.</p>
        <div className=''>
            <p className='px-8 text-3xl  font-bold text-cyan-300'>300 €</p>
        </div>
        </div>
    </div>

   
    <div className='m-auto  '>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500  mb-4 p-4'>Pack site vitrine & logo</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <div className="flex flex-wrap items-center justify-center ">
        <div className="flex-col w-1/6 shadow shadow-black pt-2  my-8 rounded mx-8  justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-cyan-800 my-4">Logo</h3>
          <img src="assets/img/utile/logoRose.png" alt="" className="object-contain  p-0 m-auto"/>
        </div>

          <p className="text-7xl text-cyan-800">+</p>

        <div className="flex-col w-1/6 shadow shadow-black pt-2  my-8 rounded mx-8  justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-cyan-800 my-4">Logo</h3>
          <img src="assets/img/utile/logoRose.png" alt="" className="object-contain  p-0 m-auto"/>
        </div>
        <p className="text-7xl text-cyan-800">+</p>

<div className="flex-col w-1/6 shadow shadow-black pt-2  my-8 rounded mx-8  justify-center items-center space-y-8 text-center p-0 ">
  <h3 className="font-medium text-3xl text-cyan-800 my-4">Logo</h3>
  <img src="assets/img/utile/logoRose.png" alt="" className="object-contain  p-0 m-auto"/>
</div>

        </div>
        <p>Idéal pour les petites entreprises ou indépendants.</p>
        </div>
        
    </div>

 
</section>

    <h2 className='text-5xl text-center m-auto' id='infographie'>Infographie</h2> <br />
<section className='text-white flex justify-center flex-wrap  space-x-8 space-y-8 mb-48 '>
    <div></div>
    <div className='   m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Création de logo</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>   3 propositions de logos originales</li> <br />
           <li> 2 révisions incluses</li> <br />
           <li> Fichiers haute définition pour impression <br /> et web (PNG, JPG, PDF, SVG)</li> <br />
           <li> Manuel d’utilisation du logo (en option : +50 €)</li> <br />
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">300 €</p>
        </ul>
        </div>
    </div>

   
    <div className='md:w-1/3  m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Conception de flyers</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>Création d’un flyer recto/verso personnalisé (A5, A4 ou autre format)</li> <br />
           <li> Fichiers haute définition prêts pour l’impression</li> <br />
           <li> 2 révisions incluses</li> <br />
           <li> Possibilité de création de pack flyers + affiches (devis sur demande)</li> <br />
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">150 €</p>
        </ul>
        </div>
    </div>

    <div className='md:w-1/3  m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Charte graphique</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>   Création de votre logo + choix de couleurs et typographies</li> <br />
           <li> Conception de templates pour vos supports de communication (cartes de visite, flyers, brochures)</li> <br />
           <li> Fichiers prêts à l’impression et au web</li> <br />
           <li> 2 révisions incluses</li> <br />
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">600 €</p>
        </ul>
        </div>
    </div>
</section>

<h2 className='text-5xl' id='infographie'>Web Design</h2> 
<section className='text-white flex justify-center flex-wrap space-x-8 space-y-8 md:mt-0 md:pt-0 mb-48 '>
    <div></div>
    <div className=' md:w-1/3   m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Création de site vitrine</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>   Design personnalisé et responsive (adapté pour mobile et tablette)</li> <br />
           <li> 4 à 6 pages (Accueil, Services, À propos, Contact, etc.)</li> <br />
           <li> Intégration des contenus (textes et images fournis par le client)</li> <br />
           <li> Référencement de base (SEO)</li> <br />
           <li> Livraison avec formation sur la gestion du site</li> <br />
           <li> Maintenance en option (150 €/an)</li> <br />
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">900 €</p>
        </ul>
        </div>
    </div>

   
    <div className='   m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4  p-4'>Maquette graphique (site ou application)</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>Maquette de votre site web ou application sur Figma</li> <br />
           <li> Design moderne et personnalisé</li> <br />
           <li> Jusqu’à 3 pages ou écrans inclus</li> <br />
           <li> 2 révisions gratuites</li> <br />
           <li> Idéal pour la présentation à des développeurs ou investisseurs</li> <br />
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">400 €</p>
        </ul>
        </div>
    </div>

  
</section>

<h2 className='text-5xl' id='infographie'>Photographie</h2> <br />
<section className='text-white flex justify-center flex-wrap space-x-8 space-y-8  mb-48'>
    <div></div>
    <div className='    m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Séance Portrait Premium</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>   3 heures de shooting en extérieur</li> <br />
           <li> 40 photos retouchées</li> <br />
           <li> Fichiers haute définition pour impression <br /> et web (PNG, JPG, PDF, SVG)</li> <br />
         
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">450 €</p>
        </ul>
        </div>
    </div>

   
    <div className=' m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Pack Portrait Pro</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>Séance de 45 minutes en studio ou sur site</li> <br />
           <li> 5 photos retouchées pour CV, LinkedIn, ou site web</li> <br />
           <li> Livraison numérique en haute définition</li> <br />
   
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">200 €</p>
        </ul>
        </div>
    </div>

    <div className=' m-auto'>
        <div className='flex-col rounded card  p-4'>
        <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Pack Entreprise</h1> 
        <hr className='w-1/2 p-0 m-auto' />
        <ul className='my-4'>
        <li>   6 heures de shooting pour couvrir l’équipe ou un événement</li> <br />
           <li> 150 photos retouchées</li> <br />
           <li> Fichiers prêts à l’impression et au web</li> <br />
          
        <p className="bg-cyan-800 p-4 shad rounded text-xl font-bold">1200 €</p>
        </ul>
        </div>
    </div>
</section>





<section>
    <h1 className='text-3xl text-cyan-500 mb-4 p-4'>Pourquoi choisir Astra Lux  ?</h1>
    <ul>
        <li><strong>Créativité & Expertise :</strong> Des créations graphiques et des photos de haute qualité, pensées pour vous démarquer.</li> <br />
        <li><strong>Solutions complètes :</strong> De la création d’un site vitrine à votre identité visuelle complète, je vous propose des solutions adaptées à votre activité.</li> <br />
        <li><strong>Proximité et flexibilité :</strong> Basé à Caudry, je travaille avec des clients dans toute la région du Nord de la France, avec des offres personnalisables selon vos besoins.</li> <br />
    </ul>
</section>

<section>
    <h2 className='text-3xl text-cyan-500 mb-4 p-4'>Me contacter</h2>
    <p>📞 <strong>Téléphone</strong> : 07 87 26 09 64</p>
    <p>📧 <strong>E-mail</strong> : <a href="mailto:zoe.dero@gmail.com">zoe.dero@gmail.com</a></p>
    <p>📍 <strong>Localisation</strong> : Caudry, Hauts-de-France</p>
</section>



    </div>
  )
}
 */

import React from "react";

export default function Prestations() {
  return (
    <div className="pt-4 text-white bg-cyan-900  m-auto text-center  ">
    
      <section className="text-white  flex justify-center  flex-wrap space-x-8 space-y-16 pb-48 w-full  ">
        <div></div>
    
        <div className=" w-full pt-28 m-auto">
  {/* Pack Lancement Entreprise */}
  <div className="flex-col rounded p-4">
    <h1 className="text-3xl text-cyan-400 mb-4 p-4">
      Pack lancement entreprise
    </h1>
    <hr className="w-1/2 p-0 m-auto" />
    <div className="flex flex-wrap md:items-start md:justify-center justify-center md:space-x-8">
      {/* Carte Cartes de visite */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Cartes de visite
        </h3>
        <img
          src="assets/img/carteV.jpg"
          alt="Cartes de visite"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">125 €</p>
        <p className="text-white text-sm">Design personnalisé pour vos cartes.</p>
      </div>

      {/* Carte Logo */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Logo
        </h3>
        <img
          src="assets/img/logoC.png"
          alt="Logo"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">375 €</p>
        <p className="text-white text-sm">Création d'un logo unique pour votre marque.</p>
      </div>

      {/* Carte Flyer */}
      <div className="flex-col md:w-1/6 card shadow w-5/6 shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Flyer
        </h3>
        <img
          src="assets/img/Verso.jpg"
          alt="Flyer"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">250 €</p>
        <p className="text-white text-sm">Flyers attractifs pour promouvoir votre activité.</p>
      </div>





<div className=" w-full pt-28 m-auto">
  {/* Pack Site Vitrine */}
  <div className="flex-col rounded p-4">
    <h1 className="text-3xl text-cyan-400 mb-4 p-4">
      Pack Site Vitrine
    </h1>
    <hr className="w-1/2 p-0 m-auto" />
    <div className="flex flex-wrap md:items-start md:justify-center justify-center md:space-x-8">

      {/* Carte Site Vitrine */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Site Vitrine
        </h3>
        <img
          src="assets/img/nouvSiteKeren.png"
          alt="Site Vitrine"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">1,650 €</p>
        <p className="text-white text-sm">Un site pour présenter votre activité.</p>
      </div>

      {/* Carte Création de contenu */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Création de contenu
        </h3>
        <img
          src="assets/img/cbdCremes.jpg"
          alt="Création de contenu"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">500 €</p>
        <p className="text-white text-sm">Contenu de qualité pour votre site.</p>
      </div>

      {/* Carte Maquettes */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Maquettes
        </h3>
        <img
          src="assets/img/maquette.png"
          alt="Maquettes"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">450 €</p>
        <p className="text-white text-sm">Maquettes pour visualiser votre projet.</p>
      </div>

      {/* Carte Charte Graphique */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Charte Graphique
        </h3>
        <img
          src="assets/img/charte.jpg"
          alt="Charte Graphique"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">500 €</p>
        <p className="text-white text-sm">Création d'une charte graphique complète.</p>
      </div>
    </div>
   
  </div>
  
</div>

<div className=" w-full pt-28 m-auto">
  
{/*   <div className="flex-col rounded p-4">
    <h1 className="text-3xl text-cyan-400 mb-4 p-4">
      Pack Infographie
    </h1>
    <hr className="w-1/2 p-0 m-auto" />
    <div className="flex flex-wrap md:items-start md:justify-center justify-center md:space-x-8">

      
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Logo
        </h3>
        <img
          src="assets/img/logoC.png"
          alt="Logo"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">375 €</p>
        <p className="text-white text-sm">Création d'un logo unique pour votre marque.</p>
      </div>

     
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Émoticônes
        </h3>
        <img
          src="assets/img/emoticones.jpg"
          alt="Émoticônes"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">200 €</p>
        <p className="text-white text-sm">Création d'un pack d'émoticônes personnalisées.</p>
      </div>

      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Overlay Streamer
        </h3>
        <img
          src="assets/img/overlay.jpg"
          alt="Overlay Streamer"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">300 €</p>
        <p className="text-white text-sm">Création d'overlays personnalisés pour vos streams.</p>
      </div>
    </div>
  
  </div> */}
  
</div>

<div className=" w-full pt-28 m-auto">
  {/* Pack Photographie */}
  <div className="flex-col rounded p-4">
    <h1 className="text-3xl text-cyan-400 mb-4 p-4">
      Pack Photographie
    </h1>
    <hr className="w-1/2 p-0 m-auto" />
    <div className="flex flex-wrap md:items-start md:justify-center justify-center md:space-x-8">
      {/* Carte Portraits */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Portraits
        </h3>
        <img
          src="assets/img/thomas.JPG"
          alt="Portraits"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">200 €</p>
        <p className="text-white text-sm">Séance photo professionnelle.</p>
      </div>

      {/* Carte Animaux */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Animaux
        </h3>
        <img
          src="assets/img/cloé2.jpg"
          alt="Animaux"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">150 €</p>
        <p className="text-white text-sm">Photos professionnelles de vos animaux de compagnie.</p>
      </div>

      {/* Carte Bébé */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Bébé
        </h3>
        <img
          src="assets/img/adeliaBulle.jpg"
          alt="Bébé"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">250 €</p>
        <p className="text-white text-sm">Séance photo adorable pour bébés.</p>
      </div>

      {/* Carte Grossesse */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Grossesse
        </h3>
        <img
          src="assets/img/gross.jpg"
          alt="Grossesse"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">300 €</p>
        <p className="text-white text-sm">Capturez ce moment précieux de votre grossesse.</p>
      </div>

      {/* Carte Entreprises */}
      <div className="flex-col card md:w-1/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-400 my-4">
          Entreprises
        </h3>
        <img
          src="assets/img/entreprise.jpg"
          alt="Entreprises"
          className="object-cover  p-0 m-auto"
        />
        <p className="text-cyan-400 text-2xl">400 €</p>
        <p className="text-white text-sm">Photographie professionnelle pour votre entreprise.</p>
      </div>
    </div>
  
  </div>
 
</div>
</div>
</div>
</div>
</section>
</div>



    
     
    
  );
}
