import React from 'react'
import { NavLink } from 'react-router-dom'
import {BsFillArrowUpCircleFill} from 'react-icons/bs';

export default function Footer() {
  return (
    <div className='flex items-center space-y-4 bg-cyan-900   mb-0 pb-2'>
      <ul className='text-center text-lg font-medium  mb-0  mx-auto p-0 space-y-2 m-6'>
        <li><NavLink to="/" className=" text-white ">Home</NavLink></li>
        <li><NavLink to="/galerie" className="text-white">Projets</NavLink></li>
        <li><NavLink to="/contact" className="text-white">Contact</NavLink></li>
        <li><NavLink to="/informations" className="text-white">Informations</NavLink></li>
        <br />
        <p className='text-center m-auto text-white'>CopyRight@ DEROZIER Zoé</p>
      </ul>
    <a href="#top" className='pr-12 text-white absolute bottom-30 right-0 '><BsFillArrowUpCircleFill size={40} /></a>
    <br />
    </div>
  )
}
