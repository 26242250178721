import'./contactScreen.css';

import React, { useRef } from 'react';



export default function ContactScreen() {




      

  return (
    <div className='   text-white py-6  bg-cyan-900  m-auto space-y-8 '>
      

      <section className='m-auto  text-center text-white space-y-16 px-4 text-2xl py-28'>
     

     <h1 className='font-bold text-center text-white text-5xl mb-8 pt-8'>Contact</h1>
 <div className='flex w-5/6 flex-wrap w-full justify-center items-center m-auto  space-y-8'>
   <div className=' m-auto justify-center text-center md:justify-end'>
   <iframe title="plan" className='rounded m-auto shadow  shadow-black'
   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.344815903026!2d3.417301276916882!3d50.11726931129738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c299f41c019209%3A0xadd75fffbd7b9bca!2s1%20Rue%20Madame%20de%20S%C3%A9vign%C3%A9%2C%2059540%20Caudry!5e0!3m2!1sfr!2sfr!4v1729374496818!5m2!1sfr!2sfr" width="auto" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
<div className='flex flex-col  m-auto space-y-12'>

 <div className='flex-col text-start '>
     <div className='flex-col md:space-y-2'>
     <button className='w-full p-4  rounded '>
       
     <div className='flex-col '>
         <div className="text-2xl text-center">📍</div>            
         <p className='text-white'>1 rue madame de Sevigné <br />59540 Caudry</p>
       </div>

     </button>
     <br />
     

     <button className='w-full text-center p-4 hover:rounded'>
       
     <div className='flex-col justify-center items-center'>
         <h2 className=''><img src="../../img/mail.png" alt="" className='m-auto' /></h2> 
         <p className="text-2xl text-center">📧 </p>
            <a href="mailto:zoe@astra-lux.fr" className=' text-white font-normal'><p>zoe@astra-lux.fr</p></a>
       </div>

     </button>
     <br />

     <button className='flex justify-center items-center space-x-4   w-full text-center p-2  rounded '>
       <div className='flex-col text-start'>
         <div className="text-2xl text-center">📞</div>            
         <p className='text-white'>07 87 26 09 64</p>
       </div>

     </button>
   


     </div>
   
   </div>




</div>
</div>
</section>

      {/* <form action="submit"  className='flex flex-col w-full text-white items-center space-y-4  '>
        <label htmlFor="nom" className=' text-3xl text-white'>Nom</label>
        <input type="text" className='border-b-2 border-cyan-500 rounded text-center m-6 hover:outline-none outline-none w-5/6 md:w-1/5 mt-0'/>
<br />
        <label htmlFor="prenom" className=' text-3xl text-white'>Prénom</label>
        <input type="text" id="prenom" className='border-b-2 border-cyan-500 rounded text-center  m-6 hover:outline-none outline-none w-5/6 md:w-1/5 mt-0'/>
<br />
        <label htmlFor="genre" className=' text-3xl text-white'>Pour quel genre de shooting ?</label>
        <input type="text" id="genre" placeholder='Ex : Mariage, animalier, sport, portrait ...' className='border-b-2 text-center border-cyan-500 rounded w-5/6 md:w-1/5 m-6 hover:outline-none outline-none mt-0'/>
<br />
        <label htmlFor="email" className='f text-3xl text-white'>E-mail</label>
        <input type="text" className='border-b-2 border-cyan-500 rounded  m-6 hover:outline-none outline-none w-5/6 md:w-1/5 mt-0'/>
<br />
        <label htmlFor="message" className=' text-3xl text-white'>Votre demande</label>
        <textarea name="" placeholder="N'hesitez pas à détailler au maximum votre demande." id="message" cols="30" rows="10" className='border-b-2 border-cyan-500 rounded text-center m-6 hover:outline-none outline-none w-5/6 md:w-1/5 mt-0'></textarea>
<br />
        <button type='submit' className='btn card text-white text-3xl rounded p-4 hover:text-cyan-400 hover:shadow hover:shadow-cyan-400 hover:bg-white '>Envoyer</button>
      </form> */}
    </div>
  )
}
